import React, { Component } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import PDF from '../../assets/Covenants.pdf';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import {Helmet} from 'react-helmet'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


class Covenants extends Component {
    state = {
        numPages: null,
        pageNumber: 1,
    };

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    goToPrevPage = () =>
        this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
    goToNextPage = () =>
        this.setState(state => ({ pageNumber: state.pageNumber + 1 }));

    navhandler = () => {
        if(this.state.pageNumber >= 5) {
            return (
                <nav className='covenant__nav--nav'>
                    <span onClick={this.goToPrevPage}><FaArrowLeft/></span>
                    <div/>
                </nav>
            )
        } else if (this.state.pageNumber <= 1) {
            return (
                <nav className='covenant__nav--nav'>
                    <div/>
                    <span onClick={this.goToNextPage}><FaArrowRight/></span>
                </nav>
            )
        } else {
            return (
                <nav className='covenant__nav--nav'>
                    <span onClick={this.goToPrevPage}><FaArrowLeft/></span>
                    <span onClick={this.goToNextPage}><FaArrowRight/></span>
                </nav>
            )
        }
    };

    render() {
        const { pageNumber, numPages } = this.state;

        return (
            <div className='covenant'>
                <Helmet>
                    <title>Covenants - Heritage Pines</title>
                    <meta name="description" content="Look up and read the Heritage Pine Covenants if you are new to the area."/>
                    <meta name="keywords" content="https://www.heritagepines.org , heritagepines.org, Heritage Pines, www/heritagepines.org, Heritage Pines Improvement Association, contact, Tony, Genova, heritage pines convenants"/>
                </Helmet>
                <div className='covenant__body animated fadeIn fast'>
                    <Document
                        file={PDF}
                        onLoadSuccess={this.onDocumentLoadSuccess}
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                </div>
                <div className='covenant__nav'>
                    <p>Page {pageNumber} of {numPages}</p>
                    {this.navhandler()}
                </div>
            </div>
        );
    }
}

export default Covenants;
