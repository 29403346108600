import React, { Component } from 'react';
import { connect } from 'react-redux';
import { API, graphqlOperation } from 'aws-amplify';
import { createCommissioners, deleteCommissioners, createCityContacts, deleteCityContacts } from '../../graphql/mutations';
import { listCommissionerss, listCityContactss } from '../../graphql/queries';
import NotificationBar from "../universalComps/notifyBar";
import Loader from "react-loader-spinner";
import { FaTrash } from 'react-icons/fa';

class CityContacts extends Component {

    state = {
        loading: true,
        commissioner: '',
        commissioners: {},
        commissionerLoading: false,
        commissionerError: '',
        commissionerDelete: false,
        cityContacts: {},
        cityLoad: false,
        name: '',
        number: '',
        nameError: '',
        numberError: '',
        error: false,
    };

    async componentDidMount() {
        await API.graphql(graphqlOperation(listCommissionerss))
            .then(data =>{
                this.setState({ commissioners: data.data.listCommissionerss.items });
                API.graphql(graphqlOperation(listCityContactss))
                    .then(data => {
                        this.setState({ cityContacts: data.data.listCityContactss.items, loading: false })
                    })
                console.log(data);
            })
            .catch(err => console.log(err))
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    };

    commissionerValidate = () => {
        let commissionerError = '';
        commissionerError = this.state.commissioner.length < 1 ? 'Please input name': null;
        if (commissionerError) {
            this.setState({ commissionerError });
            return false
        } else {
            return true
        }
    };

    cityValidate = () => {
        let nameError = '';
        let numberError = '';
        nameError = this.state.name.length < 1 ? 'Please input name': null;
        numberError = this.state.number.length < 1 ? 'Please input number': null;
        if (nameError || numberError){
            this.setState({ nameError, numberError });
            return false
        } else {
            return true
        }
    };

    addCommissioner = async e => {
        e.preventDefault();
        this.setState({ commissionerLoading: true });
        const { commissioner } = this.state;
        const isValid = this.commissionerValidate();
        if (isValid) {
            const input = {
                name: commissioner
            };
            await API.graphql(graphqlOperation(createCommissioners, { input }))
                .then(data => {
                    console.log(data);
                    const { commissioners } = this.state;
                    commissioners.push(data.data.createCommissioners);
                    this.setState({ commissionerLoading: false, error: false, commissionerError: '', commissioner: '' })
                })
                .catch(err => {
                    this.setState({ commissionerLoading: false, error: true })
                })
        } else {
            this.setState({ commissionerLoading: false })
        }
    };

    addCommissionerForm = () => {
        const { commissionerLoading } = this.state;
        if (this.props.ISADMIN) {
            return (
                <form onSubmit={this.addCommissioner} className='city__form'>
                    <span style={{color: 'red'}}>
                        {this.state.commissionerError}
                    </span>
                    <label className='city__form--label'>
                        <input
                            type='text'
                            name='commissioner'
                            onChange={this.onChange}
                            value={this.state.commissioner}
                        />
                        {commissionerLoading ? <Loader
                            type="ThreeDots"
                            color="#689b8b"
                            height={30}
                            width={30}
                        /> :
                            <button type='submit' className='btn'>
                                Add Commissioner
                            </button>
                        }
                    </label>
                </form>
            )
        }
    };

    deleteCommissioner = async (id) => {
        this.setState({ commissionerDelete: true });
        const { commissioners } = this.state;
        const input = { id: id };
        const result = await API.graphql(graphqlOperation(deleteCommissioners, { input }))
        const deleteId = result.data.deleteCommissioners.id;
        const updatedState = commissioners.filter(commissioners => commissioners.id !== deleteId);
        this.setState({ commissioners: updatedState, commissionerDelete: false })
    };

    addCityForm = () => {
        const { cityLoad, nameError, numberError } = this.state;
        return (
            <form onSubmit={this.addCityContacts} className='city__form1'>
                <label className='city__form1--label'>
                    <span>
                        Name
                    </span>
                    <span style={{color: 'red'}}>{nameError}</span>
                    <input
                        type='text'
                        name='name'
                        onChange={this.onChange}
                        value={this.state.name}
                    />
                </label>
                <label className='city__form1--label'>
                    <span>
                        Phone Number
                    </span>
                    <span style={{color: 'red'}}>
                        {numberError}
                    </span>
                    <input
                        type='number'
                        name='number'
                        onChange={this.onChange}
                        value={this.state.number}
                    />
                </label>
                {cityLoad ? <Loader
                    type="ThreeDots"
                    color="#689b8b"
                    height={30}
                    width={30}
                />: <button type='submit' className='btn'>
                    Add
                </button>}
            </form>
        )
    };

    addCityContacts = async e => {
        e.preventDefault();
        this.setState({ cityLoad: true });
        const isValid = this.cityValidate();
        if (isValid) {
            const { name, number, cityContacts } = this.state;
            const input = {name, number};
            await API.graphql(graphqlOperation(createCityContacts, { input }))
                .then(data => {
                    cityContacts.push(data.data.createCityContacts);
                    this.setState({ cityLoad: false, numberError: '', nameError: '', error: false, name:'', number:'' });
                })
        } else {
            this.setState({ cityLoad: false })
        }
    };

    deleteCity = async (id) => {
        const { cityContacts } = this.state;
        const input = { id: id };
        const result = await API.graphql(graphqlOperation(deleteCityContacts, { input }))
        const deleteId = result.data.deleteCityContacts.id;
        const updatedState = cityContacts.filter(cityContacts => cityContacts.id !== deleteId);
        this.setState({ cityContacts: updatedState })
    };

    render() {
        const { error, loading, commissioners, cityContacts } = this.state;
        console.log(commissioners);
        if (loading) {
            return (
                <div className='home'>
                    <div className='loader'>
                        <Loader
                            type="ThreeDots"
                            color="#689b8b"
                            height={80}
                            width={80}
                        />
                    </div>
                </div>
            )
        } else {
            return (
                <div className='city'>
                    {error ? <NotificationBar className='errorBar' message='There was a issue, Please try again' /> : null}
                    <h1 className='city__header'>
                        City Contacts
                    </h1>
                    <div className='city__container'>
                        <h1>
                            Margate Commissioners
                        </h1>
                            {commissioners.map((data) => {
                                return (
                                    <div key={data.id} className='city__container--commissioners'>
                                        <h4>{data.name}</h4>
                                        {this.props.ISADMIN ? <FaTrash
                                            className='city__container--deleteIcon'
                                            onClick={() => this.deleteCommissioner(data.id)}
                                        />: null}
                                    </div>
                                )
                            })}
                        {this.props.ISADMIN ? this.addCommissionerForm() : null}
                    </div>
                    <div className='city__container'>
                        <h1>
                            City Contact Numbers
                        </h1>
                        {cityContacts.map((data) => {
                            return (
                                <div key={data.id} className='city__container--commissioners'>
                                    <h4>
                                        {data.name}
                                    </h4>
                                    <h4>
                                        {data.number}
                                    </h4>
                                    {this.props.ISADMIN ? <FaTrash
                                        className='city__container--deleteIcon'
                                        onClick={() => this.deleteCity(data.id)}
                                    />: null}
                                </div>
                            )
                        })}
                        {this.props.ISADMIN ? this.addCityForm(): null}
                    </div>
                </div>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        ISADMIN: state.admin
    }
};

export default connect(mapStateToProps, null)(CityContacts);
