import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { createPost } from '../../graphql/mutations';
import NotificationBar from "../universalComps/notifyBar";
import Loader from "react-loader-spinner";
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToMarkdown from 'draftjs-to-markdown';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {Link} from "react-router-dom";

class CreatePage extends Component {

    state = {
        cardTitle: '',
        cardDescription : '',
        postTitle: '',
        date: '',
        loading: false,
        submitted: false,
        error: false,
        editorState: undefined,
        markDown: ''
    };

    componentDidMount() {
        const date = new Date();
        this.setState({ date })
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    handleChangePost = event => {
        this.setState({ [event.target.name]: event.target.value })
    };

    handleAddPost = event => {
        event.preventDefault();
        this.setState({ loading: true });
        const { editorState } = this.state;
        const markDown = editorState && draftToMarkdown(convertToRaw(editorState.getCurrentContent()))

        const input = {
            id: this.state.cardTitle,
            header: this.state.cardTitle,
            description: this.state.cardDescription,
            dateCreated: this.state.date,
            content: {
                header: this.state.postTitle,
                description: markDown
            }
        };
        console.log(input);
        API.graphql(graphqlOperation(createPost, { input }))
            .then( data => {this.setState({ submitted: true, error: false, loading: false })})
            .catch( err => this.setState({ error: true, loading: false, submitted: false }));
    };


    render() {
        const { submitted, error, loading } = this.state;
        return (
            <div className='create animated fadeIn fast'>
                {submitted ? <NotificationBar className='successBar' message='Post has been successfully submitted' /> : null}
                {error ? <NotificationBar className='errorBar' message='There was a issue, Please try again' /> : null}
                <form className='create__form' onSubmit={this.handleAddPost}>
                    <div className='create__form--hdiv'>
                        <h2 className='create__form--header'>
                            Create a post
                        </h2>
                    </div>
                    <div className='create__form--Card'>
                        <div className='create__form--Card--L'>
                            <h3>
                                Post Card
                            </h3>
                        </div>
                        <div className='create__form--Card--R'>
                            <label className='create__form--Hlabels'>
                                <h5>
                                    Post Card Header
                                </h5>
                                <input
                                    type='text'
                                    onChange={this.handleChangePost}
                                    name='cardTitle'
                                />
                            </label>
                            <label className='create__form--Tlabels'>
                                <h5>
                                    Post Card Description
                                </h5>
                                <textarea
                                    onChange={this.handleChangePost}
                                    name='cardDescription'
                                />
                            </label>
                        </div>
                    </div>
                    <div className='create__form--Card'>
                        <div className='create__form--Card--L'>
                            <h3>
                                Post Content
                            </h3>
                        </div>
                        <div className='create__form--Card--R'>
                            <label className='create__form--Hlabels'>
                                <h5>
                                    Post Header
                                </h5>
                                <input
                                    type='text'
                                    onChange={this.handleChangePost}
                                    name='postTitle'
                                />
                            </label>
                            <label className='create__form--body--Tlabels'>
                                <Editor
                                    onEditorStateChange={this.onEditorStateChange}
                                    wrapperClassName='editor'
                                    editorClassName='editor__Text'
                                    toolbar={{
                                        options: ['inline', 'blockType', 'list'],
                                        inline: { inDropdown: true },
                                        list: { inDropdown: true },
                                        textAlign: { inDropdown: true },
                                        link: { inDropdown: true },
                                        history: { inDropdown: true },
                                    }}
                                />
                            </label>
                        </div>
                    </div>
                    <div className='create__form--Card--btn'>
                        <Link to='/'
                            style={{marginRight:'15px'}}
                        >
                            <button type='button' className='btn'>
                                Back to home
                            </button>
                        </Link>
                        {loading ? <Loader
                            type="ThreeDots"
                            color="#689b8b"
                            height={30}
                            width={30}
                        /> :
                            <button type='submit' className='btn'>
                                Submit
                            </button>
                        }
                    </div>
                </form>
            </div>
        )
    }
}



export default CreatePage;
