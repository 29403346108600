import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import {Link} from "react-router-dom";
import Loader from "react-loader-spinner";
import NotificationBar from "../universalComps/notifyBar";
import { connect } from 'react-redux';
import * as actionTypes from "../../store/actions";

class SignIn extends Component {

    state = {
        loading: false,
        email: '',
        password: '',
        error: false,
    };

    onChange = e => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value })
    };

    onSubmit = e => {
        e.preventDefault();
        this.setState({ loading: true });
        const { email, password } = this.state;
        Auth.signIn(email, password)
            .then(data => {
                this.setState({ error: false, loading: false });
                this.props.setSignInStatus(true);
                this.props.setUserData(data.attributes);
                const roles = data.signInUserSession.idToken.payload['cognito:groups'];
                if (!roles) {
                    this.props.setRole(false)
                } else {
                    this.props.setRole(true)
                }
                this.props.history.push('/');
            })
            .catch(err => {
                this.checkIfConfirm(err);
                console.log(err)
            })
    };

    checkIfConfirm = (data) => {
        if (data.code === 'UserNotConfirmedException') {
            this.props.history.push('/confirmAccount')
        } else {
            this.setState({ error: true, loading: false });
        }
    };

    handleLoading = () => {
        const { loading } = this.state;
        if (loading) {
            return (
                <Loader
                    type="ThreeDots"
                    color="#689b8b"
                    height={30}
                    width={30}
                />
            )
        } else {
            return (
                <button className='btn'>
                    Sign In
                </button>
            )
        }
    };

    render() {
        const { error } = this.state;
            return (
                <div className='auth'>
                    {error ? <NotificationBar className='errorBar' message='There was a issue'/> : null}
                    <div className='auth__container animated fadeIn'>
                        <h2>
                            Sign In
                        </h2>
                        <form className='auth__container--form' onSubmit={this.onSubmit}>
                            <label className='auth__container--form--labels'>
                            <span>
                                Email
                            </span>
                                <input
                                    type='text'
                                    name='email'
                                    onChange={this.onChange}
                                />
                            </label>
                            <label className='auth__container--form--labels'>
                            <span>
                                Password
                            </span>
                                <input
                                    type='password'
                                    name='password'
                                    onChange={this.onChange}
                                />
                            </label>
                            {this.handleLoading()}
                        </form>
                        <div className='auth__container--bottomNav'>
                            <Link to='/createAccount'>
                                Create Account
                            </Link>
                            <Link to='/forgotPassword'>
                                Forgot Password
                            </Link>
                        </div>
                    </div>
                </div>
            )

    }
}

const mapDispatchToProps = dispatch => {
    return {
        setRole: (id) => dispatch({type: actionTypes.ISADMIN, data: id}),
        setUserData: (id) => dispatch({type: actionTypes.USERDATA, data: id}),
        setSignInStatus: (id) => dispatch({ type: actionTypes.ISSIGNEDIN, data: id })
    }
};

export default connect(null, mapDispatchToProps)(SignIn);
