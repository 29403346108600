import React from 'react';

const Footer = () => {
    return(
        <div className='footer'>
            <h3>
                Heritage Pines Improvement Association Inc.
            </h3>
            <h3>
                Margate, Florida 33093-4796
            </h3>
        </div>
    )
};

export default Footer;
