import React, { Component } from 'react';
import {Link} from "react-router-dom";
import Loader from "react-loader-spinner";
import NotificationBar from "../universalComps/notifyBar";
import { Auth } from 'aws-amplify';

class ConfirmAccount extends Component{

    state = {
        loading: false,
        email: '',
        code: '',
        error: false
    };

    componentDidMount() {
        console.log(this.props.state)
    }

    onChange = e => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name] : value })
    };

    onSubmit = e => {
        e.preventDefault();
        this.setState({ loading: true });
        const { email, code } = this.state;
        Auth.confirmSignUp(email, code)
            .then(data => {
                this.setState({ loading: false, error: false });
                this.props.history.push('/signIn');
                console.log(data);
            })
            .catch(err => {
                this.setState({ loading: false, error: true });
                console.log(err)
            })
    };

    handleLoading = () => {
        const { loading } = this.state;
        if (loading) {
            return (
                <Loader
                    type="ThreeDots"
                    color="#689b8b"
                    height={30}
                    width={30}
                />
            )
        } else {
            return (
                <button className='btn'>
                    Confirm Account
                </button>
            )
        }
    };

    render() {
        const { error } = this.state;
        return (
            <div className='auth'>
                {error ? <NotificationBar className='errorBar' message='There was a issue'/> : null}
                <div className='auth__container animated fadeIn'>
                    <h2>
                        Confirm Account
                    </h2>
                    <span className='auth__container--message'>
                        Code is sent to your email, check spam folder if not present.
                    </span>
                    <form className='auth__container--form' onSubmit={this.onSubmit}>
                        <label className='auth__container--form--labels'>
                            <span>
                                Email
                            </span>
                            <input
                                type='text'
                                name='email'
                                onChange={this.onChange}
                            />
                        </label>
                        <label className='auth__container--form--labels'>
                            <span>
                                Code
                            </span>
                            <input
                                type='text'
                                name='code'
                                onChange={this.onChange}
                            />
                        </label>
                        {this.handleLoading()}
                    </form>
                    <div className='auth__container--bottomNav'>
                        <Link to='/signIn'>
                            Back to Sign In
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfirmAccount;
