import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { updatePost } from '../../graphql/mutations';
import { Link } from 'react-router-dom';
import Loader from "react-loader-spinner";
import NotificationBar from "../universalComps/notifyBar";
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { markdownToDraft } from 'markdown-draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToMarkdown from "draftjs-to-markdown";

class EditBlog extends Component {

    state = {
        postID: '',
        cardTitle: '',
        cardDescription: '',
        postTitle: '',
        creator: '',
        date: '',
        loading: false,
        submitted: false,
        error: false,
        editorState: undefined,
        markDown: ''
    };

    componentDidMount() {
        this.setState({
            postID: this.props.location.state.id,
            cardTitle: this.props.location.state.header,
            cardDescription: this.props.location.state.description,
            postTitle: this.props.location.state.content.header,
        });
        this.convertToDraft();
        const date = new Date();
        this.setState({ date })
    };

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    convertToDraft = () => {
        const { editorState } = this.state;
        const defaultValue = this.props.location.state.content.description;
        const rawData = markdownToDraft(defaultValue);
        const contentState = convertFromRaw(rawData);
        const newEditorState = EditorState.createWithContent(contentState);
        this.setState({ editorState: newEditorState });
    };

    handleChangePost = event => {
        this.setState({ [event.target.name]: event.target.value })
    };

    handleEditPost = async event => {
        event.preventDefault();
        this.setState({ loading: true });
        const { editorState } = this.state;
        const markDown = editorState && draftToMarkdown(convertToRaw(editorState.getCurrentContent()))
        const input = {
            id: this.state.postID,
            header: this.state.cardTitle,
            description: this.state.cardDescription,
            dateCreated: this.state.date,
            content: {
                header: this.state.postTitle,
                description: markDown
            }
        };
        API.graphql(graphqlOperation(updatePost, {input}))
            .then(data => {this.setState({  error: false, loading: false, submitted: true })})
            .catch(err => { this.setState({ error: true, loading: false, submitted: false}) })
    };

    render() {
        const { error, loading, editorState, submitted } = this.state;
        return (
            <div className='create animated fadeIn fast'>
                {submitted ? <NotificationBar className='successBar' message='Post has been successfully submitted' /> : null}
                {error ? <NotificationBar className='errorBar' message='There was a issue, Please try again' /> : null}
                <form className='create__form' onSubmit={this.handleEditPost}>
                    <div className='create__form--hdiv'>
                        <h2 className='create__form--header'>
                            Editing post
                        </h2>
                    </div>
                    <div className='create__form--Card'>
                        <div className='create__form--Card--L'>
                            <h3>
                                Post Card
                            </h3>
                        </div>
                        <div className='create__form--Card--R'>
                            <label className='create__form--Hlabels'>
                                <h5>
                                    Post Card Header
                                </h5>
                                <input
                                    type='text'
                                    onChange={this.handleChangePost}
                                    name='cardTitle'
                                    value={this.state.cardTitle}
                                />
                            </label>
                            <label className='create__form--Tlabels'>
                                <h5>
                                    Post Card Description
                                </h5>
                                <textarea
                                    onChange={this.handleChangePost}
                                    name='cardDescription'
                                    value={this.state.cardDescription}
                                />
                            </label>
                        </div>
                    </div>
                    <div className='create__form--Card'>
                        <div className='create__form--Card--L'>
                            <h3>
                                Post Content
                            </h3>
                        </div>
                        <div className='create__form--Card--R'>
                            <label className='create__form--Hlabels'>
                                <h5>
                                    Post Header
                                </h5>
                                <input
                                    type='text'
                                    onChange={this.handleChangePost}
                                    name='postTitle'
                                    value={this.state.postTitle}
                                />
                            </label>
                            <label className='create__form--body--Tlabels'>
                                <Editor
                                    editorState={editorState}
                                    onEditorStateChange={this.onEditorStateChange}
                                    wrapperClassName='editor'
                                    editorClassName='editor__Text'
                                    toolbar={{
                                        options: ['inline', 'blockType', 'list'],
                                        inline: { inDropdown: true },
                                        list: { inDropdown: true },
                                        textAlign: { inDropdown: true },
                                        link: { inDropdown: true },
                                        history: { inDropdown: true },
                                    }}
                                />
                            </label>
                        </div>
                    </div>
                    <div className='create__form--Card--btn'>
                        <Link to='/'
                              style={{marginRight:'15px'}}
                        >
                            <button type='button' className='btn'>
                                Back to home
                            </button>
                        </Link>
                        {loading ? <Loader
                                type="ThreeDots"
                                color="#689b8b"
                                height={30}
                                width={30}
                            /> :
                            <button type='submit' className='btn'>
                                Submit
                            </button>
                        }
                    </div>
                </form>
            </div>
        )
    }
}

export default EditBlog;
