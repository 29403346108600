import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ContactForm from './contactForm';
import Markdown from 'react-markdown';

class BlogContent extends Component {
    render() {
        const dateToString = d => `${('00' + (d.getMonth() + 1)).slice(-2)}-${('00' + d.getDate()).slice(-2)}-${d.getFullYear()}`;
        const theDate = new Date(Date.parse(this.props.location.state.dateCreated));

        return(
            <div className='blogContent'>
                <div className='blogContent__container animated fadeIn fast'>
                    <div className='blogContent__container--header'>
                        <h1>
                            {this.props.location.state.header}
                        </h1>
                        <h5>
                            {dateToString(theDate)}
                        </h5>
                    </div>
                    <div className='blogContent__container--body'>
                        <h2>
                            {this.props.location.state.content.header}
                        </h2>
                        <Markdown source={this.props.location.state.content.description}/>
                    </div>
                    <Link to={'/'} className='Link'>
                        View All Post
                    </Link>
                    <ContactForm/>
                </div>
            </div>
        )
    }
}

export default BlogContent;
