import React, { Component } from 'react';
import '../scss/main.scss';
import Loader from 'react-loader-spinner';
import ScrollToTop from 'react-router-scroll-top';
import "animate.css/animate.min.css";

//REDUX
import { connect } from 'react-redux';
import * as actionTypes from '../store/actions';

//AUTH/API
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';

//ROUTES
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from '../components/landing/home';
import CreatePage from '../components/Blog/createPage';
import BlogContent from '../components/Blog/blogContent';
import NavBar from '../components/navBar/navBar';
import Footer from '../containers/footer/footer';
import Contact from '../containers/contact/contact';
import Covenant from '../components/covenants/covenants';
import img from "../assets/Pines.png";
import EditBlog from '../components/Blog/editBlog';
import SignIn from '../components/auth/signIn';
import CreateAccount from '../components/auth/createAccount'
import ConfirmAccount from "../components/auth/confirmAccount";
import ForgotPassword from '../components/auth/forgotPassword';
import ResetPassword from "../components/auth/resetPassword";
import CityContacts from "../components/cityContacts/cityContacts";

Amplify.configure(awsconfig);

class App extends Component {

    state = {
        loading: true
    };

    componentDidMount() {
        this.checkIfUser();
    }

    checkIfUser = async () => {
        Auth.currentAuthenticatedUser()
            .then(data => {
                if (data.attributes.email === process.env.REACT_APP_LOGIN) {
                    this.props.setSignInStatus(false);
                    this.setState({ loading: false })
                } else {
                    this.setState({ loading: false });
                    this.props.setSignInStatus(true);
                    this.props.setUserData(data.attributes);
                    const roles = data.signInUserSession.idToken.payload['cognito:groups'];
                    if (!roles) {
                        this.props.setRole(false)
                    } else {
                        this.props.setRole(true)
                    }
                }
            })
            .catch(err => {
                if (err === 'not authenticated') {
                    Auth.signIn(process.env.REACT_APP_LOGIN, process.env.REACT_APP_PASSWORD)
                        .then(data => {
                            this.props.setSignInStatus(false);
                            this.setState({ loading: false })
                        })
                }
            })
    };

    render() {
        const { loading } = this.state;
        if (loading) {
            return (
                <div className='loader'>
                    <Loader
                        type="ThreeDots"
                        color="#689b8b"
                        height={80}
                        width={80}
                    />
                </div>
            )
        } else {
            return (
                <Router>
                    <ScrollToTop>
                        <>
                            {/*NavBar*/}
                            <NavBar signOut={this.handleSignOut}/>
                            {/*Routes*/}
                            <div
                                style={{
                                    backgroundImage: 'url(' + img + ')',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center center',
                                    backgroundRepeat: 'no-repeat',
                                }}
                            >
                                <Route path='/' exact component={Home}/>
                                <Route path='/create' component={CreatePage}/>
                                <Route path='/blog/:id' component={BlogContent}/>
                                <Route path='/contact' component={Contact}/>
                                <Route path='/covenant' component={Covenant}/>
                                <Route path='/signIn' component={SignIn}/>
                                <Route path='/confirmAccount' component={ConfirmAccount}/>
                                <Route path='/createAccount' component={CreateAccount}/>
                                <Route path='/forgotPassword' component={ForgotPassword}/>
                                <Route path='/resetPassword' component={ResetPassword}/>
                                <Route path='/edit/:id' component={EditBlog}/>
                                <Route path='/cityContacts' component={CityContacts}/>
                            </div>
                            <Footer/>
                        </>
                    </ScrollToTop>
                </Router>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        AdminState: state.admin
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setRole: (id) => dispatch({type: actionTypes.ISADMIN, data: id}),
        setUserData: (id) => dispatch({type: actionTypes.USERDATA, data: id}),
        setSignInStatus: (id) => dispatch({ type: actionTypes.ISSIGNEDIN, data: id })
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
