import React, { Component } from 'react';
import { connect } from 'react-redux'
import {Link, withRouter} from 'react-router-dom';
import { Auth } from 'aws-amplify'
import * as actionTypes from "../../store/actions";
import Loader from "react-loader-spinner";


class NavBar extends Component {

    state = {
        signedOut: false,
        loading: false
    };

    adminNavbar = () => {
        if(this.props.ISADMIN) {
            return(
                <li>
                    <Link to='/create' className='Navbar__left--links'>Create Post</Link>
                </li>
            )
        }
    };

    signOut = async () => {
        this.setState({ loading: true });
        await Auth.signOut()
            .then(data => {
                Auth.signIn(process.env.REACT_APP_LOGIN,process.env.REACT_APP_PASSWORD)
                    .then(data => {
                        this.setState({ loading: false });
                        this.props.history.push('/signIn');
                        this.props.setSignInStatus(false);
                        this.props.setUserData({});
                        this.props.setRole(false)
                    })
                    .catch(err => console.log(err))
            })
            .catch(err => console.log(err))
    };

    signedIn = () => {
        const { loading } = this.state;
        if (this.props.SIGNEDIN) {
            return (
                <ul className='Navbar__right'>
                    <li>
                        Hi, {this.props.USERDATA.email}
                    </li>
                    {this.adminNavbar()}
                    <li>
                        {loading ? <Loader
                                type="ThreeDots"
                                color="white"
                                height={30}
                                width={30}
                            /> :
                            <button onClick={this.signOut} className='btn'>
                                Sign Out
                            </button>
                        }
                    </li>
                </ul>
            )
        } else {
            return (
                <ul className='Navbar__right'>
                    <li>
                        <Link to='/signIn'>
                            <button className='btn'>
                                Sign In
                            </button>
                        </Link>
                    </li>
                </ul>
            )
        }
    };

    render() {

        return(
            <div className='Navbar'>
                <div className='Navbar__left'>
                    <div>
                        <h1>
                            Heritage Pines Improvement Association Inc.
                        </h1>
                    </div>
                    <ul>
                        <li>
                            <Link className='Navbar__left--links' to='/'>Home</Link>
                        </li>
                        <li>
                            <Link className='Navbar__left--links' to='/contact'>Contacts</Link>
                        </li>
                        <li>
                            <Link className='Navbar__left--links' to='/covenant'>Covenants</Link>
                        </li>
                        <li>
                            <Link className='Navbar__left--links' to='/cityContacts'>City Contacts</Link>
                        </li>
                    </ul>
                </div>
                {this.signedIn()}
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        ISADMIN: state.admin,
        USERDATA: state.userData,
        SIGNEDIN: state.signedIn
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setRole: (id) => dispatch({type: actionTypes.ISADMIN, data: id}),
        setUserData: (id) => dispatch({type: actionTypes.USERDATA, data: id}),
        setSignInStatus: (id) => dispatch({ type: actionTypes.ISSIGNEDIN, data: id })
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
