/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPost = `mutation CreatePost($input: CreatePostInput!) {
  createPost(input: $input) {
    id
    header
    description
    dateCreated
    content {
      header
      description
    }
  }
}
`;
export const updatePost = `mutation UpdatePost($input: UpdatePostInput!) {
  updatePost(input: $input) {
    id
    header
    description
    dateCreated
    content {
      header
      description
    }
  }
}
`;
export const deletePost = `mutation DeletePost($input: DeletePostInput!) {
  deletePost(input: $input) {
    id
    header
    description
    dateCreated
    content {
      header
      description
    }
  }
}
`;
export const createCommissioners = `mutation CreateCommissioners($input: CreateCommissionersInput!) {
  createCommissioners(input: $input) {
    id
    name
  }
}
`;
export const updateCommissioners = `mutation UpdateCommissioners($input: UpdateCommissionersInput!) {
  updateCommissioners(input: $input) {
    id
    name
  }
}
`;
export const deleteCommissioners = `mutation DeleteCommissioners($input: DeleteCommissionersInput!) {
  deleteCommissioners(input: $input) {
    id
    name
  }
}
`;
export const createCityContacts = `mutation CreateCityContacts($input: CreateCityContactsInput!) {
  createCityContacts(input: $input) {
    id
    name
    number
  }
}
`;
export const updateCityContacts = `mutation UpdateCityContacts($input: UpdateCityContactsInput!) {
  updateCityContacts(input: $input) {
    id
    name
    number
  }
}
`;
export const deleteCityContacts = `mutation DeleteCityContacts($input: DeleteCityContactsInput!) {
  deleteCityContacts(input: $input) {
    id
    name
    number
  }
}
`;
