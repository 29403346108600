/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPost = `query GetPost($id: ID!) {
  getPost(id: $id) {
    id
    header
    description
    dateCreated
    content {
      header
      description
    }
  }
}
`;
export const listPosts = `query ListPosts(
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      header
      description
      dateCreated
      content {
        header
        description
      }
    }
    nextToken
  }
}
`;
export const getCommissioners = `query GetCommissioners($id: ID!) {
  getCommissioners(id: $id) {
    id
    name
  }
}
`;
export const listCommissionerss = `query ListCommissionerss(
  $filter: ModelCommissionersFilterInput
  $limit: Int
  $nextToken: String
) {
  listCommissionerss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
    }
    nextToken
  }
}
`;
export const getCityContacts = `query GetCityContacts($id: ID!) {
  getCityContacts(id: $id) {
    id
    name
    number
  }
}
`;
export const listCityContactss = `query ListCityContactss(
  $filter: ModelCityContactsFilterInput
  $limit: Int
  $nextToken: String
) {
  listCityContactss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      number
    }
    nextToken
  }
}
`;
