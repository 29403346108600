import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import Loader from "react-loader-spinner";
import NotificationBar from "../universalComps/notifyBar";
import {Link} from "react-router-dom";
import { FaCheck } from 'react-icons/fa'

class CreateAccount extends Component {

    state = {
        loading: false,
        email: '',
        password: '',
        confirmPassword: '',
        passwordConfirmed: false,
        passwordError: '',
        error: false,
    };

    handleChange = e => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name] : value }, () => {this.validate()})
    };

    validate = () => {
        const { password, confirmPassword } = this.state;
        if (password === confirmPassword && password.length > 1) {
            console.log('password confirmed?');
            this.setState({ passwordConfirmed: true, passwordError: '' });
            return true
        } else if (password.length > 1) {
            this.setState({ passwordConfirmed: false, passwordError: 'Please confirm password' });
            return false
        }
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({ loading: true });
        const isValid = this.validate();
        console.log(isValid);
        if (isValid) {
            const { email, password } = this.state;
            Auth.signUp(email, password)
                .then(data => {
                    this.setState({ loading: false, error: false });
                    this.props.history.push('/confirmAccount');
                    console.log(data)
                })
                .catch(err => {this.setState({ error: true, loading: false })})
        } else {
            this.setState({ loading: false })
        }
    };

    handleLoading = () => {
        const { loading } = this.state;
        if (loading) {
            return (
                <Loader
                    type="ThreeDots"
                    color="#689b8b"
                    height={30}
                    width={30}
                />
            )
        } else {
            return (
                <button className='btn'>
                    Create Account
                </button>
            )
        }
    };

    render() {
        const { error, passwordConfirmed, passwordError } = this.state;

            return (
                <div className='auth'>
                    {error ? <NotificationBar className='errorBar' message='There was a issue'/> : null}
                    <div className='auth__container animated fadeIn'>
                        <h2>
                            Create Account
                        </h2>
                        <form className='auth__container--form' onSubmit={this.handleSubmit}>
                            <label className='auth__container--form--labels'>
                            <span>
                                Email
                            </span>
                                <input
                                    type='text'
                                    name='email'
                                    onChange={this.handleChange}
                                />
                            </label>
                            <label className='auth__container--form--labels'>
                            <span>
                                Password
                            </span>
                                <input
                                    type='password'
                                    name='password'
                                    onChange={this.handleChange}
                                />
                            </label>
                            <label className='auth__container--form--labels'>
                            <span>
                                Confirm Password
                                {passwordConfirmed ? <FaCheck style={{color: 'green', marginLeft: '5px'}}/>:null}
                            </span>
                            <span style={{color: 'red'}}>{passwordError}</span>
                            <input
                                type='password'
                                name='confirmPassword'
                                onChange={this.handleChange}
                            />
                            </label>
                            {this.handleLoading()}
                        </form>
                        <div className='auth__container--bottomNav'>
                            <Link to='/signIn'>
                                Back to Sign In
                            </Link>
                        </div>
                    </div>
                </div>
            )

    }
}

export default CreateAccount;
