import React, { Component } from 'react';
import NotificationBar from "../universalComps/notifyBar";
import { FaCheck } from 'react-icons/fa'
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import Loader from "react-loader-spinner";

class ResetPassword extends Component {

    state = {
        email: '',
        code: '',
        password: '',
        confirmPassword: '',
        passwordConfirmed: false,
        passwordError: '',
        error: false,
        submitted: false,
        loading: false
    };

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value }, () => this.validate())
    };

    validate = () => {
        const { password, confirmPassword } = this.state;
        if (password === confirmPassword && password.length > 1) {
            this.setState({ passwordConfirmed: true, passwordError: '' });
            return true
        } else if (password.length > 1) {
            this.setState({ passwordConfirmed: false, passwordError: 'Please confirm password' });
            return false
        }
    };

    onSubmit = async e => {
        e.preventDefault();
        this.setState({ loading: true });
        const isValid = this.validate();
        if (isValid) {
            const { email, code, password } = this.state;
            Auth.forgotPasswordSubmit(email, code, password)
                .then(data => {
                    console.log(data)
                    this.setState({ loading: false, submitted: true, error: false })
                })
                .catch(err => console.log(err))
        } else {
            console.log('error');
            this.setState({ error: true, loading: false, submitted: false })
        }
    };

    render() {
        const { error, passwordConfirmed, passwordError, submitted, loading } = this.state;
        if (submitted) {
            return (
                <div className='auth'>
                    <div className='auth__container'>
                        <h2>
                            Your password has been reset!
                        </h2>
                        <span className='auth__container--message'>
                            Please click the button to be redirected to the sign in page.
                        </span>
                        <Link to='/signIn'>
                            <button className='btn'>
                                Go to sign in
                            </button>
                        </Link>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='auth'>
                    {error ? <NotificationBar className='errorBar' message='There was a issue'/> : null}
                    <div className='auth__container'>
                        <h2>
                            Reset Password
                        </h2>
                        <span className='auth__container--message'>
                        Code was sent to your email
                    </span>
                        <form className='auth__container--form' onSubmit={this.onSubmit}>
                            <label className='auth__container--form--labels'>
                            <span>
                                Email
                            </span>
                                <input
                                    type='email'
                                    name='email'
                                    onChange={this.onChange}
                                />
                            </label>
                            <label className='auth__container--form--labels'>
                            <span>
                                Code from email
                            </span>
                                <input
                                    type='text'
                                    name='code'
                                    onChange={this.onChange}
                                />
                            </label>
                            <label className='auth__container--form--labels'>
                            <span>
                                New password
                            </span>
                                <input
                                    type='password'
                                    name='password'
                                    onChange={this.onChange}
                                />
                            </label>
                            <label className='auth__container--form--labels'>
                            <span>
                                Confirm new password
                                {passwordConfirmed ? <FaCheck style={{color: 'green', marginLeft: '5px'}}/>:null}
                            </span>
                                <span style={{color: 'red'}}>{passwordError}</span>
                                <input
                                    type='password'
                                    name='confirmPassword'
                                    onChange={this.onChange}
                                />
                            </label>
                            {loading ? <Loader
                                    type="ThreeDots"
                                    color="#689b8b"
                                    height={30}
                                    width={30}
                                /> :
                                <button className='btn' type='submit'>
                                    Reset Password
                                </button>
                            }
                        </form>
                    </div>
                </div>
            )
        }
    }
}

export default ResetPassword;
