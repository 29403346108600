// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_BwmEmjcQW",
    "aws_user_pools_web_client_id": "62osnk5a7chi06sa7pf0ksd6k6",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://psh5pqmib5efphfzjyecemxvka.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "hpiaRest",
            "endpoint": "https://or8tsprtij.execute-api.us-east-1.amazonaws.com/hpiaprod",
            "region": "us-east-1"
        }
    ],
    "aws_content_delivery_bucket": "hpiahostingbucket-0-hpiaprod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dq5twj4u8g6v2.cloudfront.net"
};


export default awsmobile;
