import React, { Component } from 'react';
import {API, graphqlOperation} from "aws-amplify";
import {listPosts} from "../../graphql/queries";
import { Link } from "react-router-dom";
import { deletePost } from '../../graphql/mutations';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import {Helmet} from 'react-helmet'


class Home extends Component{

    state = {
        post: [],
        reDirect: 'home',
        postContent: {},
        postID: "",
        loading: true,
        clicked: false,
    };

    async componentDidMount() {
        await API.graphql(graphqlOperation(listPosts))
            .then(data=> {
                const post = data.data.listPosts.items;
                this.sortPost(post)
            })
            .catch(err=> console.log(err));
    }

    sortPost = (post) => {
        let sortedPost = post.sort((a, b) => {
            if(Date.parse(a.dateCreated) > Date.parse(b.dateCreated)) return -1;
            if(Date.parse(a.dateCreated) < Date.parse(b.dateCreated)) return 1;
            return 0;
        });
        this.setState({ post: sortedPost, loading: false })
    };

    showDelete = () => {
        if(this.state.clicked) {
            return (
                <div className='delete animated fadeIn'>
                    <div className='delete__box animated slideInUp'>
                        <span>
                            Are you sure you want to Delete this post?
                        </span>
                        <div className='delete__box--btns'>
                            <button className='btn' onClick={() => this.cancelDelete()}>
                                Cancel
                            </button>
                            <button className='btn' onClick={() => this.submitDelete()}>
                                Yes
                            </button>
                        </div>

                    </div>
                </div>
            )
        }
    };

    cancelDelete = () => {
        this.setState({ clicked: false })
    };

    submitDelete = async () => {
        const { post } = this.state;
        const input = { id: this.state.postID };
        const result = await API.graphql(graphqlOperation(deletePost, { input }));
        const deletedPostId = result.data.deletePost.id;
        const updatedPost = post.filter(post => post.id !== deletedPostId);
        this.setState({ post: updatedPost, clicked: false })
    };

    setDelete = (id) => {
        this.setState({ clicked: true, postID: id })
    };

    handleAdminRoles = (id, data) => {
        if(this.props.AdminState) {
            return(
                <div>
                    <button className='btn1' onClick={() => this.setDelete(id)}>
                        Delete Post
                    </button>
                    <Link className='btn1' to={{
                        pathname: '/edit/' + id,
                        state: data
                    }}
                    >
                        Edit Post
                    </Link>
                </div>
            )
        } else {
            return null
        }
    };

    render() {
        const { post, loading } = this.state;
        if(loading) {
            return (
                <div className='home'>
                    <div className='loader'>
                        <Loader
                            type="ThreeDots"
                            color="#689b8b"
                            height={80}
                            width={80}
                        />
                    </div>
                </div>

            )
        } else {
            return (
                <div className='home'
                >
                    <Helmet>
                        <title>Blog - Heritage Pines</title>
                        <meta name="description" content="Welcome to Heritage Pines Online blog post. Check Out whats new things are happening."/>
                        <meta name="keywords" content="https://www.heritagepines.org ,Heritage Pines, heritagepines.org, www.heritagepines.org, Heritage Pines Improvement Association, contact, Tony, Genova, heritage pines blog"/>
                    </Helmet>
                    {this.showDelete()}
                    {post.map((data) => {
                        const dateToString = d => `${('00' + (d.getMonth() + 1)).slice(-2)}-${('00' + d.getDate()).slice(-2)}-${d.getFullYear()}`;
                        const theDate = new Date(Date.parse(data.dateCreated));
                        return(
                            <div className='blogCard animated fadeIn fast' key={data.id}>
                                <div className='blogCard--head'>
                                    <h1>
                                        {data.header}
                                    </h1>
                                    {this.handleAdminRoles(data.id, data)}
                                </div>
                                <h5>
                                    {dateToString(theDate)}
                                </h5>
                                <p>
                                    {data.description}
                                </p>
                                <Link to={{
                                    pathname: '/blog/' + data.id,
                                    state: data
                                }}>
                                    Continue Reading
                                </Link>
                            </div>
                        )
                    })}
                </div>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        AdminState: state.admin
    }
};

export default connect(mapStateToProps, null)(Home);
