import React from 'react'
import {Helmet} from 'react-helmet'


const Card = (name, role, phone) => {
    return (
        <div className='contact__container--card'>
            <h1 className='contact__container--card--h1'>
                {name}
            </h1>
            <h2 className='contact__container--card--h2'>
                {role}
            </h2>
            <span className='contact__container--card--span'>
                {phone}
            </span>
        </div>
    )
};

const Contact = () => {
    return (
        <div className='contact' id='contactPage'>
            <Helmet>
                <title>Contact - Heritage Pines</title>
                <meta name="description" content="Heritage Pines Improvement Association Inc. Contact Page"/>
                <meta name="keywords" content="https://heritagepines.org ,Heritage Pines, heritagepines.org, Heritage Pines Improvement Association, contact, Tony, Genova"/>
            </Helmet>
            <h1>
                Heritage Pines Improvement Association Inc.
            </h1>
            <h2>
                Board of directors and officers
            </h2>
            <div className='contact__container animated fadeIn fast'>
                {Card(
                    'Anthony J. Genova',
                    'President / Treasurer',
                    '954-979-3331'
                )}
                {Card(
                    'Donna Schmidt',
                    'Secretary',
                    '954-973-1969'
                )}
                <h1 className='contact__container--card--h1'>
                    Directors
                </h1>
                <div className='contact__container--directors'>
                    {Card(
                        'Betty Reid',
                        'Director',
                        '786-447-8605'
                    )}
                    {Card(
                        'Roland DaSilva',
                        'Director',
                        '954-974-1862'
                    )}
                    {Card(
                        'Jim O\'Hare',
                        'Director',
                        '954-970-3032 '
                    )}
                </div>
            </div>
        </div>
    )
};

export default Contact;
