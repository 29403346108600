import React, { Component } from 'react';
import { API } from 'aws-amplify';
import Loader from "react-loader-spinner";

const initialState = {
    name: '',
    email: '',
    message: '',
    nameError: '',
    emailError: '',
    messageError: '',
    submitted: null,
    loading: false
};

class ContactForm extends Component {



    state = initialState;

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value });
    };

    validate = () => {
        let nameError = '';
        let emailError = '';
        let messageError = '';

        nameError = this.state.name.length < 1 ? 'Please fill out name field': null;
        emailError = this.state.email.length < 1 ? 'Please fill out email field': null;
        messageError = this.state.message.length < 1 ? 'Please fill out message field': null;

        if (nameError || emailError || messageError) {
            this.setState({ nameError, emailError, messageError });
            return false
        }
        return true
    };

    sendEmail = async event => {
        event.preventDefault();
        this.setState({ loading: true });
        const isValid = this.validate();
        if (isValid) {
            const { name, email, message } = this.state;
            await API.post('hpiaRest', '/contact', {
                body: {
                    name,
                    email,
                    message
                }
            })
                .then(data => {
                    this.setState({ submitted: true, loading: false })
                })
                .catch( err => {this.setState({ submitted: false, loading: false })});
        } else {
            this.setState({ loading: false })
        }
    };

    handleSubmit = () => {
        if(this.state.submitted) {
            return (
                <div className='delete animated fadeIn'>
                    <div className='delete__box animated fadeInUp'>
                        <span>
                            Your Email Has been sent
                        </span>
                        <div className='delete__box--btns'>
                            <button className='btn' onClick={() => {this.setState({ submitted: null })}}>
                                Click here to continue
                            </button>
                        </div>
                    </div>
                </div>
            )
        } else if(this.state.submitted === false) {
            return (
                <div className='delete animated fadeIn'>
                    <div className='delete__box animated fadeInUp'>
                        <span>
                            There was a error sending your email. Try submitted it again!
                        </span>
                        <div className='delete__box--btns'>
                            <button className='btn' onClick={() => {this.setState({ submitted: null })}}>
                                Go back
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
    };

    render() {
        const { loading } = this.state;
        return(
            <div className='contact' id='contactForm'>
                {this.handleSubmit()}
                <h1 className='contact__h1'>
                    Have any comments or concerns?
                </h1>
                <form className='contact__form' onSubmit={this.sendEmail}>
                    <label className='contact__form--inputLabel'>
                        <h2>
                            EMAIL
                        </h2>
                        <span className='animated fadeInUp' style={{ color: 'red' }} >{this.state.emailError}</span>
                        <input
                            type='email'
                            name='email'
                            onChange={this.handleChange}
                        />
                    </label>
                    <label className='contact__form--inputLabel'>
                        <h2>
                            NAME
                        </h2>
                        <span className='animated fadeInUp' style={{ color: 'red' }} >{this.state.nameError}</span>

                        <input
                            type='text'
                            name='name'
                            onChange={this.handleChange}

                        />
                    </label>
                    <label className='contact__form--textLabel'>
                        <h2>
                            MESSAGE
                        </h2>
                        <span className='animated fadeInUp' style={{ color: 'red' }} >{this.state.messageError}</span>
                        <textarea
                            name='message'
                            onChange={this.handleChange}

                        />
                    </label>
                    {loading ? <Loader
                            type="ThreeDots"
                            color="#689b8b"
                            height={30}
                            width={30}
                        /> :
                        <button type='submit' className='btn'>
                            Send email
                        </button>
                    }
                </form>
            </div>
        )
    }
}

export default ContactForm;
