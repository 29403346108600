import React from 'react';

const NotificationBar = (prop) => {
    return (
        <div className={prop.className + ' animated fadeInDown'}>
            <span>
                {prop.message}
            </span>
        </div>
    )
};

export default NotificationBar


