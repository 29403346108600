import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import Loader from "react-loader-spinner";
import NotificationBar from "../universalComps/notifyBar";

class ForgotPassword extends Component {

    state = {
        email: '',
        emailError: '',
        error: false,
        loading: false
    };

    onchange = e => {
        this.setState({ [e.target.name]: e.target.value })
    };

    validate = () => {
        let emailError = '';
        emailError = this.state.email.length < 1 ? 'Please put email address in': null;
        if (emailError) {
            this.setState({ emailError });
            return false
        }
        return true
    };

    onSubmit = async e => {
        e.preventDefault();
        this.setState({ loading: true });
        const isValid = this.validate();
        console.log(isValid);
        if (isValid) {
            const { email } = this.state;
            await Auth.forgotPassword(email)
                .then(data => {
                    this.setState({ loading: false });
                    this.props.history.push('/resetPassword')
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ loading: false, error: true })
                })
        } else {
            this.setState({ loading: false })
        }
    };


    render() {
        const { loading, emailError, error } = this.state;
        return (
            <div className='auth'>
                {error ? <NotificationBar className='errorBar' message='There was a issue'/> : null}
                <div className='auth__container'>
                    <h2>
                        Forgot Password
                    </h2>
                    <span className='auth__container--message'>
                        Code to reset your password will be sent to your email address that you signed up with.
                    </span>
                    <form className='auth__container--form' onSubmit={this.onSubmit}>
                        <label className='auth__container--form--labels'>
                            <span>
                                Email
                            </span>
                            <span style={{color: 'red'}}>
                                {emailError}
                            </span>
                            <input
                                type='email'
                                name='email'
                                onChange={this.onchange}
                            />
                        </label>
                        {loading ? <Loader
                            type="ThreeDots"
                            color="#689b8b"
                            height={30}
                            width={30}
                        /> :
                            <button className='btn' type='submit'>
                                Send code
                            </button>
                        }
                    </form>
                </div>
            </div>
        )
    }
}
export default ForgotPassword
