import * as actionTypes from './actions';

const initialState = {
    admin: false,
    userData: {},
    signedIn: false
};

const reducer = ( state = initialState, action ) => {
    switch (action.type) {
        case actionTypes.ISADMIN:
            return{
                ...state,
                admin: action.data
            };
        case actionTypes.USERDATA:
            return {
                ...state,
                userData: action.data
            };
        case actionTypes.ISSIGNEDIN:
            return {
                ...state,
                signedIn: action.data
            };
        default:
            return state;
    }
};

export default reducer;
